<template>
  <div>
    <Modal
      @close="toRegister"
      id="modalRegisterFailed"
      :title="modalTitle"
      @ok="toRegister"
    >
      <p style="text-align:center">{{ modalText }}</p>
    </Modal>
    <Modal
      @close="toMain"
      id="modalRegisterSuccessfully"
      :title="modalTitle"
      @ok="toMain"
    >
      <p style="text-align:center">{{ modalText }}</p>
    </Modal>
  </div>
</template>

<script>
import AutorizationService from '@/services/AutorizationService';

import Modal from '@/components/Modal.vue';

import autorization from '@/mixins/autorization';
import modal from '@/mixins/modal';

export default {
  mixins: [autorization, modal],
  components: {
    Modal,
  },
  created() {
    this.checkToken();
  },
  data() {
    return {
      modalText: '',
      modalTitle: '',
      headers: undefined,
    };
  },
  methods: {
    async checkToken() {
      try {
        const { headers } = await AutorizationService.confirm({ token: this.$route.query.token });
        this.headers = headers;
        this.modalText = 'Registered successfully';
        this.modalTitle = 'Thanks for the register. Go to the website';
        this.showModal('modalRegisterSuccessfully');
      } catch (error) {
        console.log(error);
        this.modalText = 'Invalid token. Try again';
        this.modalTitle = 'Registered failed';
        this.showModal('modalRegisterFailed');
      }
    },
    toRegister() {
      this.$router.push({ name: 'register' });
    },
    toMain() {
      this.setAutorization(this.headers);
    },
  },
};
</script>
